
:root{
  --color1: #3c95b3; //#E2712C;
  --color2: #3c95b3; //#02477E;
  --color3: #08A7E1;
  --weiss: #FFFFFF;
  --schwarz: #000000;
  --grau1: #F4F4F4;
  --grau2: #E6E6E6;
  --grau3: #707070;
  --grau5: #bdbdbd;
  --gruen1: #d9ebc6;
  --headerFontWeight: bold;
  --blue: #707070; //#08A7E1;
  --darkblue: #000000; //#02477E;
  --startGreen: #A6CC7F;
  --startGreenLight: #D9EBC6;
  --subpageH2: black; //#02477E;
  --compositeImageBackground: #d7d7d7;
  // v2
  --grauBG: #F0F0F0;
  --grauBGSteps: #F2F2F2;
  --blueColor: #000000; //#5B8AA4;
  --blueColorHover: #333333; //#789EB3;
  --textColor: #797876;
  --captionColor: #707070;
  --fieldBorderColor: #BCBCBC;
}

$fsContainerBorder: 1px solid red;
$CountBoxButtonRadius: 100%;
